(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/gestion.correo.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><b>Escriba el mensaje a enviar a {{paso.tcTask.actorDesc}}</b></div></div><div class="box-body"><div class="row"><div class="col-sm-12"><textarea name="email" class="form-control input-sm" rows="8" ng-model="mail.texto"></textarea></div></div><br><div class="row"><div class="col-sm-8"></div><div class="col-sm-4"><button type="button" class="btn btn-primary" ng-click="guardarCorreo()">Enviar</button></div></div></div></div>');
}]);
})();
