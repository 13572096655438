(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/enmienda.gestion.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-medkit text-red"></i> {{titulo}}</h4><p></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="aceptarRequisito(form.$valid)" novalidate=""><div class="row" ng-show="mostrarOficio"><div class="col-sm-7"></div><div class="col-sm-3" ng-show="habilitarCambioArea"><label class="control-label">¿Habilitar modificación de área de aprovechamiento?</label><switch id="aprobar" name="aprobar" ng-model="approved" on="Si" off="No" class="green" ng-change="setAprobar()"></switch></div><div class="col-sm-2"><label class="control-label" for="codigo">No. oficio</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="codigo" ng-model="codigo" placeholder="No. Oficio" ng-required="obligar"></div></div></div><table class="table i-table"><thead><tr class="success"><th width="5%" class="p-b-10" align="center">No.</th><th width="90%" class="p-b-10" align="center">Descripción</th><th width="5%" class="p-b-10" align="center"><button type="button" class="btn btn-primary btn-xs pull-right" ng-click="agregarEnmienda()"><i class="fa fa-plus" aria-hidden="true"></i></button></th></tr></thead><tbody><tr ng-repeat="item in enmienda track by $index" class="info"><td width="5%" class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.correlativo = $index + 1"></h5></td><td width="90%" class="p-b-10"><textarea name="descripcion" rows="1" cols="80" class="form-control input-sm" ng-model="item.descripcion" placeholder="Descripción de la enmienda" required="">\n' +
    '            </textarea></td><td width="5%" align="center"><button type="button" class="btn btn-danger btn-xs" title="Quitar conclusión" ng-click="borrarEnmienda(item)"><i class="fa fa-minus" aria-hidden="true"></i></button></td></tr></tbody></table><br><div class="row"><div class="col-sm-12 text-right"><button type="submit" class="btn btn-success">Guardar</button> <button type="button" class="btn btn-primary" ng-click="vistaPreviaEnmienda()">Vista previa</button> <button type="button" class="btn btn-info" ng-click="cerrarVentana()">Cerrar</button></div></div></form></div></div>');
}]);
})();
