(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/notaenvio/notaenvio.generar.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-check fa-lg"></i>Libro de {{ module }}</h2><p></p><p><small>Libro de {{ module }}</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="row"><div class="col-sm-2" ng-class="{ \'has-error\' : form.anio.$invalid && form.$submitted }"><label class="control-label" for="anio">Año</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="anio" ng-model="data.anio" placeholder="Año" numbers-only="" required=""></div></div><div class="col-sm-2" ng-class="{ \'has-error\' : form.mes.$invalid && form.$submitted }"><label class="control-label" for="mes">Mes</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="mes" ng-model="data.mes" placeholder="Mes" numbers-only="" required=""></div></div><div class="col-sm-1"><br><button type="submit" class="btn btn-primary">Filtrar</button></div><div class="col-sm-1"><br><button type="button" class="btn btn-primary" ng-click="imprimirLibro()">Filtrar</button></div><div class="col-sm-1"><br><button type="button" class="btn btn-primary" ng-click="generarNotaEnvioConsumo()">Nota</button></div></div></form><br><div class="row"><div class="col-sm-12"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></div></div>');
}]);
})();
