(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/notaenvio/detalle.anulada.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-check fa-lg"></i>Notas anuladas</h2><p></p><p><small>Lista de notas anuladas</small></p></div></div><div class="box-body"><div class="row"><div class="col-sm-12"><div ui-grid="gridOptionsAnulada" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></div></div>');
}]);
})();
