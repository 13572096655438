(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/exento/informe.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-check fa-lg"></i> Lista de informes para resolución: <b>{{ttVentaExento.resolucion}}</b>, RNF: <b>{{ttVentaExento.ttExento.codigo}}</b></h4><p></p></div><button class="btn btn-success pull-right" ng-click="agregarInforme()"><i class="fa fa-plus fa-lg">Agregar</i></button></div><div class="box-body"><div class="row"><div class="col-sm-12"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></div></div>');
}]);
})();
