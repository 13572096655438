(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.requisitopoa.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Indique el número de oficio para notificar los requisitos del POA {{dataSolicitud.numero}}</h4></div><div class="panel-body"><div class="row"><div class="col-sm-3"><label class="control-label" for="codigo">No. oficio</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="codigo" ng-model="dataTask.ttSeguimientoTarea.codigo" placeholder="Oficio"></div></div></div><br><div class="row"><div class="col-sm-12 text-right"><button type="button" class="btn btn-success" ng-click="enviarTarea()">Notificar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaRequisito()">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.list">Cancelar</a></div></div></div></div>');
}]);
})();
