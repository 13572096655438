(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/clase.fuste.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Planifique la clase diamétrica {{clase.etiqueta}}</h4><p></p><p><small>Rodal: {{rodalClase.correlativo}}</small></p><p><small>especie: {{especieClase.tcEspecie.nombreCientifico}}</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormFuste(form.$valid)" novalidate=""><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col">Calidad</th><th scope="col">Arb/Clase</th><th scope="col">AB/Clase (m²)</th><th scope="col">Vol./ Clase (m³)</th><th scope="col">% a extraer</th><th scope="col">Arboles/Clase a extraer</th><th scope="col">AB (m³) a extraer</th><th scope="col">Volumen (m³) a extraer</th></tr></thead><tbody><tr ng-repeat="row in clase.fuste track by $index" class="info"><td><h5 class="f-400 m-b-5" ng-bind="row.calidadFusteDesc"></h5></td><td><h5 class="f-400 m-b-5" ng-bind="row.arbolr"></h5></td><td><h5 class="f-400 m-b-5" ng-bind="row.abr"></h5></td><td><h5 class="f-400 m-b-5" ng-bind="row.volr"></h5></td><td width="10%"><input type="text" class="form-control input-sm" name="pintervenir" ng-model="row.pintervenir" placeholder="%" ng-blur="setPorcentajeClase(row)" numbers-only="" required=""></td><td width="10%"><h5 class="f-400 m-b-5" ng-bind="row.arbolrExtraer"></h5></td><td width="10%"><h5 class="f-400 m-b-5" ng-bind="row.abrExtraer"></h5></td><td width="10%"><h5 class="f-400 m-b-5" ng-bind="row.volrExtraer"></h5></td></tr></tbody></table><br><div class="row"><div class="col-sm-9"></div><div class="col-sm-1"><button type="submit" class="btn btn-sm btn-danger">Guardar</button></div><div class="col-sm-1"><button type="button" class="btn btn-sm btn-success" ng-click="cerrarVentana()">Cancelar</button></div></div></form></div></div>');
}]);
})();
