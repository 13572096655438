(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/exento/notaelectronica.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-check fa-lg"></i> Resolución de venta: <b>{{ttVentaExento.resolucion}}</b>, RNF: <b>{{ttVentaExento.ttExento.codigo}}</b></h4><p></p></div></div><div class="box-body"><div class="row"><div class="col-sm-6"><div class="row"><div class="col-sm-9"><br><h5><b>Lista de notas utilizadas</b></h5></div><div class="col-sm-3"><br><button type="button" class="btn btn-info" ng-click="agregarNota()" title="Agregar nota y proceder a llenarla"><i class="fa fa-plus">Agregar nota</i></button></div></div><br><div class="row"><div class="col-sm-12"><div ui-grid="gridOptionsNotas" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div><br></div><div class="col-sm-6"><div class="row"><div class="col-sm-12"><h5><b>Rangos autorizados</b></h5><table class="table table-bordered" border="1"><thead><tr class="warning"><th scope="col">Inicio</th><th scope="col">Fin</th><th scope="col">Último usado</th></tr></thead><tbody><tr ng-repeat="rango in ttVentaExento.rangos track by $index"><td><h5 class="f-400 m-b-5" ng-bind="rango.inicio"></h5></td><td><h5 class="f-400 m-b-5" ng-bind="rango.fin"></h5></td><td><h5 class="f-400 m-b-5" ng-bind="rango.ultimoUsado"></h5></td></tr></tbody></table></div></div><div class="row"><div class="col-sm-9"></div><div class="col-sm-3"><br><button type="button" class="btn btn-danger" ng-click="cancelarAgregarNota()"><i class="fa fa-close">Cancelar</i></button></div></div></div></div></div></div>');
}]);
})();
