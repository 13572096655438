(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/convenioMunicipal/convenioMunicipal.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-check fa-lg"></i> {{ module }}</h2><p></p><p><small>Listado de {{ module }}</small></p></div><br><br><button class="btn btn-success pull-right" ui-sref="^.add"><i class="fa fa-plus fa-lg">Agregar</i></button></div><div class="box-body"><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.departamentoId.$invalid && form.$submitted }"><label class="control-label" for="departamentoId">Departamento</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="departamentoId" ng-model="data.tcDepartamento.departamentoId" on-select="setDepartamento($item)" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.departamentoDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.departamentoId as item in dataDepartamento | filter: {departamentoDesc: $select.search}"><div ng-bind-html="item.departamentoDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-12"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></div></div>');
}]);
})();
