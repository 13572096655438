(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/consumoFamiliar/anexo.consumo.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-hand-o-right"></i> Anexo (s)</h2><p></p></div></div><div class="box-body"><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col" class="text-center">No.</th><th scope="col" class="text-center">Descripción</th><th scope="col" class="text-center">Opción</th></tr></thead><tbody><tr ng-repeat="item in dataAnexo track by $index" class="info"><td scope="col">{{$index + 1}}</td><td scope="col">{{item.tcRequisito.requisitoDesc}}</td><td scope="col"><div class="col-sm-12"><div class="fg-line form-group"><button type="button" class="btn btn-info" ng-click="abrirAnexo(item.anexoConsumoId)">Abrir <i class="fa fa-folder-open-o" aria-hidden="true"></i></button></div></div></td></tr></tbody></table><br><div class="row"><div class="col-sm-10"></div><div class="col-sm-2"><button type="button" class="btn btn-danger" ng-click="cerrarVentana()">Cerrar</button></div></div></div></div>');
}]);
})();
