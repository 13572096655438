(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.notiinforme.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><div class="row"><div class="col-sm-9"><h4>Informe de Notas de Envío</h4></div><div class="col-sm-3" ng-show="!esProvidencia"><br><button type="button" class="btn btn-info" ng-click="providenciarTecnico()" title="Regresar tarea a técnico">Providenciar</button></div></div></div><div class="panel-body"><div class="row"><div class="col-sm-6" ng-show="!esProvidencia"><h4><b>{{titulo}}</b></h4></div><div class="col-sm-3"><label class="control-label" for="dataTask.codigo">No. {{codigo}}</label><div class="fg-line form-group"><input type="text" name="codigo" class="form-control input-sm" ng-model="dataTask.codigo" placeholder="No."></div></div><div class="col-sm-6" ng-class="{ \'has-error\' : form.tecnicoId.$invalid && form.$submitted }" ng-show="esProvidencia"><label class="control-label" for="estadoId">Técnico</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="tecnicoId" ng-model="dataTask.tcPersonaTraslado.personaId" theme="bootstrap" on-select="setTecnico($item)" sortable="true"><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.personaDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.personaId as item in dataTecnico | filter: {personaDesc: $select.search}"><div ng-bind-html="item.personaDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-8"></div><div class="col-sm-3"><button type="button" class="btn btn-success" ng-click="notificarResolucion()">Finalizar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaNotificacion()">Ver</button> <a class="btn btn-danger" ui-sref="index.tarea.exentolist">Cancelar</a></div></div></div></div>');
}]);
})();
