(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.notiavaluo.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Informe subregional de cuantificación de daños (avalúo)</h4></div><div class="panel-body"><div class="row"><div class="col-sm-3"><label class="control-label" for="dataTask.codigo">No. de oficio</label><div class="fg-line form-group"><input type="text" name="codigo" class="form-control input-sm" ng-model="dataTask.codigo" placeholder="No. de oficio"></div></div><div class="col-sm-6"></div><div class="col-sm-3"><button type="button" class="btn btn-success" ng-click="notificarInformeAvaluo()">Notificar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaNotificacionAvaluo(false)">Ver</button> <a class="btn btn-danger" ui-sref="index.tarea.avaluo">Cancelar</a></div></div></div></div>');
}]);
})();
