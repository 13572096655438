(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.tpl.html',
    '<div class="box box-success"><div class="box-header with-border"><p></p><h3><i class="fa fa-hand-o-right fa-lg"></i> Expediente: {{dataTask.ttGestion.expediente}}</h3><p></p></div><div class="box-body"><div class="slide" ui-view=""></div></div></div>');
}]);
})();
