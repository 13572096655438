(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/valortipomadera/agregar.tipomadera.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Seleccione el tipo de madera que desea agregar para la Región {{itemRegion.regionDesc}} - {{filtro.anio}}</h4><p></p><p><small>Ingrese la informaci&oacute;n</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="row"><div class="col-sm-4" ng-class="{ \'has-error\' : form.tipoMaderaId.$invalid && form.$submitted }"><label class="control-label" for="tipoMaderaId">Tipo de madera</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="tipoMaderaId" ng-model="nuevo.tipoMaderaId" on-select="setTipoMadera($item)" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.tipoMaderaDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.tipoMaderaId as item in dataTipoMadera | filter: {tipoMaderaDesc: $select.search}"><div ng-bind-html="item.tipoMaderaDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="col-sm-4" ng-class="{ \'has-error\' : form.troza.$invalid && form.$submitted }"><label class="control-label" for="troza">Troza</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="troza" ng-model="nuevo.troza" placeholder="Troza" required=""></div></div><div class="col-sm-4" ng-class="{ \'has-error\' : form.lenia.$invalid && form.$submitted }"><label class="control-label" for="lenia">Leña</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="lenia" ng-model="nuevo.lenia" placeholder="Troza" required=""></div></div></div><br><div class="row"><div class="col-sm-10"></div><div class="col-sm-1"><br><br><button type="submit" class="btn btn-primary">Guardar</button></div></div></form></div></div>');
}]);
})();
