(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.notaconsumo.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Volumen solicitado: {{dataTask.ttConsumoVenta.volumen}}. Tipo de trasporte: {{dataTask.ttConsumoVenta.tcTipoTransporte.tipoTransporteDesc}}</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="enviarTareaSolicitud(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.notas.$invalid && form.$submitted }"><label class="control-label" for="notas">Cantidad de notas</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="notas" ng-model="dataTask.ttConsumoVenta.notas" placeholder="Cantidad de notas" required="" numbers-only=""></div></div><div class="col-sm-4"><br><button type="submit" class="btn btn-success">Guardar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaNotificacion(false)">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.consumo">Cancelar</a></div></div></form></div></div>');
}]);
})();
