(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/gestion.tpl.html',
    '<style>\n' +
    '  .glyphicon.spinning {\n' +
    '    animation: spin 1s infinite linear;\n' +
    '    -webkit-animation: spin2 1s infinite linear;\n' +
    '  }\n' +
    '\n' +
    '  @keyframes spin {\n' +
    '      from { transform: scale(1) rotate(0deg);}\n' +
    '      to { transform: scale(1) rotate(360deg);}\n' +
    '  }\n' +
    '\n' +
    '  @-webkit-keyframes spin2 {\n' +
    '    from { -webkit-transform: rotate(0deg);}\n' +
    '    to { -webkit-transform: rotate(360deg);}\n' +
    '  }\n' +
    '</style><div class="box box-success"><div class="box-header with-border"><p></p><h3><i class="fa fa-hand-o-right fa-lg"></i> Plan de Manejo Forestal {{data.tipoGestionDesc}} <button type="button" class="btn btn-default" ng-click="imprimirFormato()"><i ng-show="imprimiendo == false" class="fa fa-print fa-lg"></i> <span ng-show="imprimiendo"><i class="glyphicon glyphicon-refresh spinning"></i></span> {{textoImprimiendo}}</button></h3><p></p></div><div class="box-body"><menu-tabset tabs="tabs"></menu-tabset><div class="slide" ui-view=""></div></div></div>');
}]);
})();
