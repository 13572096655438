(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/exento/venta.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-10"><p></p><h4><i class="fa fa-hand-o-right"></i> Lista de solicitudes de venta de notas de envío para el código RNF: {{dataExento.codigo}} ({{dataExento.tipoRegistroDesc}})</h4><p></p></div><div class="col-sm-2"><button class="btn btn-success pull-right" type="button" ng-click="agregarSolicitudNotas()"><i class="fa fa-plus fa-lg">Agregar nueva solicitud</i></button></div></div></div><div class="box-body"><div class="row" ng-show="mostrarAdjuntar"><div class="col-sm-12"><label class="control-label" for="codigo">Seleccione el archivo con el pago (un solo archivo PDF)</label><div class="fg-line form-group"><label ng-class="classButton">Seleccionar <i class="fa fa-file-archive-o" aria-hidden="true"></i> <input type="file" style="display: none;" id="{{ttVentaExento.ventaExentoId}}" ng-file-select=""></label></div></div></div><div class="row"><div class="col-sm-12"><div ui-grid="gridOptionsVenta" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></div></div>');
}]);
})();
