(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.listaanexos.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-10"><p></p><h4><i class="fa fa-hand-o-right"></i> Lista de anexos<p></p></h4></div></div></div><div class="panel-body"><div class="row"><div class="col-sm-12"><table class="table i-table"><thead><tr class="success"><th width="5%" class="p-b-10" align="center">No.</th><th width="85%" class="p-b-10" align="center">Descripción</th><th width="5%" scope="col" class="text-center"></th></tr></thead><tbody><tr ng-repeat="anexo in anexos track by $index" class="info"><td scope="col">{{$index + 1}}</td><td scope="col">{{anexo.nombre}}</td><td width="5%" align="center"><button type="button" class="btn btn-default btn-xs" title="Ver anexo" ng-click="verAnexoInforme(anexo)"><i class="fa fa-eye" aria-hidden="true"></i></button></td></tr></tbody></table></div></div></div></div>');
}]);
})();
