(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/holiday/holiday.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-check fa-lg"></i> {{ module }}</h2><p></p><p><small>Listado de {{ module }}</small></p></div><br><br></div><div class="box-body"><form rol="form" name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="row"><div class="col-sm-2" ng-class="{ \'has-error\' : form.year.$invalid && form.$submitted }"><label class="control-label" for="year">Año</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="year" ng-model="data.year" placeholder="Año" required="" numbers-only=""></div></div><div class="col-sm-2"><br><button type="submit" class="btn btn-primary">Obtener datos</button></div><div class="col-sm-8"><br><button class="btn btn-success pull-right" ui-sref="^.add"><i class="fa fa-plus fa-lg">Agregar</i></button></div></div></form><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
