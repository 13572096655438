(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/historial.tarea.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Historial de la gestión {{row.expediente}}</h4><p></p></div></div><div class="box-body"><table class="table i-table"><thead><tr class="success"><th width="5%" class="p-b-10" align="center">No.</th><th width="40%" class="p-b-10" align="center">Actividad</th><th width="40%" class="p-b-10" align="center">Encargado (a)</th><th width="10%" class="p-b-10" align="center">Fecha</th><th width="5%" class="p-b-10" align="center">Opción</th></tr></thead><tbody><tr ng-repeat="item in dataHistorial track by $index" class="info"><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="$index + 1"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.tcTask.taskDesc"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.tcPersonaAsignada.personaDesc"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.estadoId == 1 ? (\'No se ha resuelto, se asignó desde \' + (item.fechaRegistro | date:\'dd/MM/yyyy HH:mm\')): (item.fechaFinalizacion | date:\'dd/MM/yyyy HH:mm\')"></h5></td><td align="center"><button type="button" class="btn btn-default btn-xs" title="Ver documento (s)" ng-click="verDocumento(item)"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button> <button type="button" class="btn btn-default btn-xs" ng-show="visibleRegresar(item)" title="Anular dictamen y habilitarlo" ng-click="habilitarTarea(item)"><i class="text-danger fa fa-close" aria-hidden="true"></i></button> <button type="button" class="btn btn-default btn-xs" ng-show="visibleCambioArea(item)" title="Habilitar modificación de área" ng-click="habilitarCambioArea(item)"><i class="text-info fa fa-circle-o" aria-hidden="true"></i></button></td></tr></tbody></table></div></div>');
}]);
})();
