(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/exento/especie.exento.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-10"><p></p><h4><i class="fa fa-hand-o-right"></i> Especies actuales para el código RNF: {{dataSaldo.codigo}} ({{dataSaldo.tipoRegistroDesc}})</h4><p></p></div></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitCambiarEspecie(form.$valid)" novalidate=""><div class="row"><div class="col-sm-12"><table class="table table-bordered" border="1"><thead><tr class="warning"><th scope="col" width="50%">Especie actual</th><th scope="col" width="50%">Cambiar especie a</th></tr></thead><tbody><tr ng-repeat="especie in dataSaldo.especies track by $index"><td><h5 class="f-400 m-b-5" ng-bind="especie.tcEspecie.nombreCientifico"></h5></td><td><ui-select class="form-control input-sm" name="especieId" ng-model="especie.tcEspecieNueva.especieId" theme="bootstrap" sortable="true"><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.nombreCientifico}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.especieId as item in dataEspecie | filter: {nombreCientifico: $select.search}"><div ng-bind-html="item.nombreCientifico | highlight: $select.search"></div><div ng-bind-html="item.especieDesc | highlight: $select.search"></div></ui-select-choices></ui-select></td></tr></tbody></table></div></div><br><div class="row"><div class="col-sm-9"></div><div class="col-sm-1"><button type="submit" class="btn btn-info">Guardar <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></button></div><div class="col-sm-1"><button type="button" class="btn btn-danger" ng-click="cerrarVentana()">Cerrar</button></div></div></form></div></div>');
}]);
})();
