(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/gestion.avance.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Avance de la gestión {{row.expediente}}, NUG: {{row.nug}}</h4><p></p></div></div><div class="box-body"><div class="row"><div class="col-sm-6"><h4><b>Flujo general</b></h4><img src="img/pasos.png" alt=""></div><div class="col-sm-6"><h4><b>Pasos realizados o actuales</b></h4><timeline><timeline-event ng-repeat="paso in pasos track by $index"><timeline-badge class="{{paso.color}}"><i class="{{paso.icono}}"></i></timeline-badge><timeline-panel class="bg-info"><timeline-heading><h4><b>{{paso.tcTask.nombreCorto}}</b> ({{paso.tcTask.actorDesc}})</h4></timeline-heading><div class="row"><div class="col-sm-8"><p>{{paso.fechaDescIni}}</p><p>{{paso.fechaDescFin}}</p></div><div class="col-sm-4" ng-show="paso.estadoId == 1"><button type="button" name="button" title="Enviar correo a la persona asignada" class="btn btn-default" ng-click="enviarCorreo(paso)"><i class="fa fa-envelope-o" aria-hidden="true"></i></button></div></div></timeline-panel></timeline-event></timeline></div></div></div></div>');
}]);
})();
