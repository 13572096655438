(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/historial.avaluo.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Historial del expediente {{row.ttAvaluo.expediente}}</h4><p></p></div></div><div class="box-body"><h5><b>Tareas</b></h5><table class="table i-table"><thead><tr class="success"><th width="5%" class="p-b-10" align="center">No.</th><th width="40%" class="p-b-10" align="center">Actividad</th><th width="40%" class="p-b-10" align="center">Encargado (a)</th><th width="10%" class="p-b-10" align="center">Fecha</th><th width="5%" class="p-b-10" align="center">Opción</th></tr></thead><tbody><tr ng-repeat="item in dataTarea track by $index" class="info"><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="$index + 1"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.tcTask.taskDesc"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.tcPersonaAsignada.personaDesc"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.estadoId == 1 ? (\'No se ha resuelto, se asignó desde \' + (item.fechaRegistro | date:\'dd/MM/yyyy HH:mm\')): (item.fechaFinalizacion | date:\'dd/MM/yyyy HH:mm\')"></h5></td><td align="center"><button type="button" class="btn btn-default btn-xs" title="Ver documento (s)" ng-click="verDocumentoTareaAvaluo(item)"><i class="text-danger fa fa-file-pdf-o" aria-hidden="true"></i></button></td></tr></tbody></table></div></div>');
}]);
})();
