(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/404/404.tpl.html',
    '<style>\n' +
    '	body {\n' +
    '		color: #17384C;\n' +
    '		background-image: url("img/inicio.jpg");\n' +
    '		background-size: 100%;\n' +
    '	}\n' +
    '</style><div class="login-wrap"><p class="login-img"><img alt="logo" src="img/inab.png" width="80%"></p><h2 class="text-center">Manejo Forestal</h2><div class="alert alert-danger"><strong>¡Ups! El sistema actualmente se encuentra en mantenimiento, vuelva a intentar en otro momento</strong></div></div>');
}]);
})();
