(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/plan.requisito.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h2><b>{{tituloRequisito}}</b></h2></div><div class="panel-body"><div class="table-responsive"><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col" class="text-center">No.</th><th scope="col" class="text-center">Requisito</th></tr></thead><tbody><tr ng-repeat="item in dataRequisito track by $index" class="info"><td scope="col">{{$index + 1}}</td><td scope="col">{{item.requisitoDesc}}</td></tr></tbody></table></div><div class="row"><div class="col-sm-12 text-right"><button type="button" class="btn btn-success" ng-click="cerrarVentana()">Cerrar <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></button></div></div></div></div>');
}]);
})();
