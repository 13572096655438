(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/ingreso.codigo.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-hand-o-right"></i> {{tituloCodigo}}</h2><p></p><p><small>Ingrese la informaci&oacute;n</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormConfirmar(form.$valid)" novalidate=""><div class="row"><div class="col-sm-12" ng-class="{ \'has-error\' : form.codigo.$invalid && form.$submitted }"><div class="fg-line form-group"><label class="control-label" for="codigo">Ingrese el código</label> <input type="text" class="form-control input-sm" name="codigo" ng-model="codigo.codigo" placeholder="Codigo" required=""></div></div></div><div class="row"><div class="col-sm-6"></div><div class="col-sm-2"><button type="submit" class="btn btn-primary">Enviar</button></div><div class="col-sm-1"><button type="button" class="btn btn-default" ng-click="cerrarVentana()">Cerrar</button></div></div></form></div></div>');
}]);
})();
