(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/home/home.tpl.html',
    '<style>\n' +
    '  .content-wrapper {\n' +
    '    color: #17384C;\n' +
    '    background-image: url("img/home.jpg");\n' +
    '    background-size: 100%, 100%;\n' +
    '  }\n' +
    '</style><div class="card"><div class="card-header"><h2><small>Bienvenid@s</small></h2></div><div class="card-body card-padding"><br><br><br><div class="row"><div class="col-sm-4"></div><div class="col-sm-4 text-center text-white"><h1>Si necesita ayuda haga clic sobre el siguiente botón</h1></div></div><div class="row"><div class="col-sm-5"></div><div class="col-sm-2 text-center"><button type="button" class="btn btn-primary btn-lg" ng-click="habilitarTelefonoAyuda()"><b>Necesito ayuda</b></button></div></div><br><div class="row"><div class="col-sm-1"></div><div class="col-sm-10"><div class="panel panel-default" ng-show="mostrarTelefonoAyuda"><div class="panel-heading"><b>Seleccione el módulo para el cual necesita ayuda</b></div><div class="panel-body"><div class="row"><div class="col-sm-12"><label class="control-label" for="moduloId">Módulo</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="moduloId" ng-model="moduloId" on-select="setModuloAyuda($item)" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.moduloDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.tipoTransporteId as item in dataModuloAyuda | filter: {moduloDesc: $select.search}"><div ng-bind-html="item.moduloDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-12"><h1>Teléfono: <b>{{moduloAyuda.telefono}}</b></h1><h1>Correo: <b>{{moduloAyuda.correo}}</b></h1></div></div></div></div></div></div></div></div>');
}]);
})();
