(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.notilicencia.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Notificación de licencia forestal y generar cedula de notificación</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="notificarLicencia(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.codigo.$invalid && form.$submitted }"><label class="control-label" for="codigo">No. Oficio (Aviso)</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="codigo" ng-model="dataTask.ttSeguimientoTarea.codigo" placeholder="Oficio" required=""></div></div><div class="col-sm-3" ng-class="{ \'has-error\' : form.numeroResolucion.$invalid && form.$submitted }"><label class="control-label" for="numeroResolucion">No. Oficio DIPRONA</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="numeroResolucion" ng-model="dataTask.ttSeguimientoTarea.numeroResolucion" placeholder="Oficio" required=""></div></div><div class="col-sm-6 text-right"><button type="submit" class="btn btn-success">Notificar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaNotificacion()">Ver</button> <a class="btn btn-danger" ui-sref="index.tarea.list">Cancelar</a></div></div></form></div></div>');
}]);
})();
