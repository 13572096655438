(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.secretaria.tpl.html',
    '<div class="panel panel-primary"><div class="panel-heading"><h3>El siguiente paso es trasladar el expediente a la Dirección Subregional<br>¿Quiere realizarlo ahora? Presione si, de lo contrario puede cancelar y regresar a la lista de tareas</h3></div><div class="panel-body"><div class="row"><div class="col-sm-12"><button type="button" class="btn btn-success" ng-click="enviarTarea()">Si, enviar</button> <a class="btn btn-danger" ui-sref="index.tarea.list">Cancelar</a></div></div></div></div>');
}]);
})();
