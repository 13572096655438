(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.providencia.informe.tpl.html',
    '<uib-tabset active="active" type="pills"><uib-tab index="1" heading="Asignar tareas"><div class="panel panel-info"><div class="panel-heading"><h4>Indique el número de providencia para continuar</h4></div><div class="panel-body"><div class="row"><div class="col-sm-4"><label class="control-label" for="providencia">Número de providencia</label><div class="fg-line form-group"><input type="text" name="providencia" ng-model="dataTask.ttSeguimientoTarea.providencia" class="form-control input-sm" placeholder="Número de providencia"></div></div><div class="col-sm-6" ng-class="{ \'has-error\' : form.tecnicoId.$invalid && form.$submitted }"><label class="control-label" for="estadoId">Tecnico</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="tecnicoId" ng-model="dataTask.tcPersonaTraslado.personaId" theme="bootstrap" on-select="setTecnico($item)" sortable="true"><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.personaDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.personaId as item in dataTecnico | filter: {personaDesc: $select.search}" disabled=""><div ng-bind-html="item.personaDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><br><div class="row"><div class="col-sm-12 text-right"><button type="button" class="btn btn-success" ng-click="asignarExpediente()">Asignar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaAsignacion()">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.list">Cancelar</a></div></div></div></div></uib-tab></uib-tabset>');
}]);
})();
