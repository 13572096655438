(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/fuste.general.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Indique los porcentajes para cada calidad de fuste, que desea aplicar de manera general</h4><p></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="setFusteGeneral(form.$valid)" novalidate=""><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col">Calidad de fuste</th><th scope="col">% a extraer</th></tr></thead><tbody><tr ng-repeat="row in especieClase.calidadFuste track by $index" class="info"><td><h5 class="f-400 m-b-5" ng-bind="row.calidadFusteDesc"></h5></td><td width="30%"><input type="text" class="form-control input-sm" name="pintervenir" ng-model="row.pintervenir" placeholder="%" ng-blur="setPorcentajeClase(row)" numbers-only="" required=""></td></tr></tbody></table><br><div class="row"><div class="col-sm-6"></div><div class="col-sm-6"><button type="submit" class="btn btn-sm btn-danger">Guardar</button> <button type="button" class="btn btn-sm btn-success" ng-click="cerrarVentana()">Cancelar</button></div></div></form></div></div>');
}]);
})();
