(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.ventaconsumo.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Indique de la factura</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="enviarTarea(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.serieFactura.$invalid && form.$submitted }"><label class="control-label" for="serieFactura">Serie factura</label> <i class="fa fa-question-circle-o" title="Serie de la factura" aria-hidden="true"></i><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="serieFactura" ng-model="dataTask.ttConsumoVenta.serieFactura" placeholder="Serie factura" required=""></div></div><div class="col-sm-3" ng-class="{ \'has-error\' : form.numeroFactura.$invalid && form.$submitted }"><label class="control-label" for="numeroFactura">No. factura</label> <i class="fa fa-question-circle-o" title="Número de factura" aria-hidden="true"></i><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="numeroFactura" ng-model="dataTask.ttConsumoVenta.numeroFactura" placeholder="Serie factura" required=""></div></div><div class="col-sm-2"></div><div class="col-sm-4"><br><button type="submit" class="btn btn-success">Guardar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaResolucion(false)">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.consumo">Cancelar</a></div></div></form></div></div>');
}]);
})();
