(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/notaenvio/notaenvio.preautorizar.tpl.html',
    '<form rol="form" name="form" ng-submit="submitFormAutorizar(form.$valid)" novalidate=""><div class="panel panel-primary"><div class="panel-heading"><div class="row"><div class="col-sm-9"><h5>Detalle de la autorización</h5></div><div class="col-sm-3" ng-class="{ \'has-error\' : form.referencia.$invalid && form.$submitted }"><label class="control-label" for="referencia">Número de conocimiento</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="referencia" ng-model="data.referenciaAutorizacion" placeholder="Automático" disabled=""></div></div></div></div><div class="panel-body"><div class="row"><div class="col-sm-6" ng-class="{ \'has-error\' : form.observaciones.$invalid && form.$submitted }"><label class="control-label" for="observaciones">Observaciones</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" ng-model="data.observaciones" placeholder="Observaciones"></div></div></div><table class="table table-bordered" border="1"><thead><tr><td class="bg-success">Tipo de nota de envío</td><td class="bg-success">Solicitado</td><td class="bg-success">Cantidad a autorizar</td></tr></thead><tbody><tr ng-repeat="detalle in data.detalle track by $index"><td width="50%" class="bg-info"><h5 class="f-400 m-b-5" ng-bind="detalle.tcTipoNotaEnvio.tipoNotaEnvioDesc"></h5></td><td width="25%" class="bg-info"><h5 class="f-400 m-b-5" ng-bind="detalle.cantidad"></h5></td><td class="bg-info"><input type="text" class="form-control input-sm" ng-model="detalle.cantidadAutorizada" placeholder="Cantidad a autorizar" numbers-only="" required=""></td></tr></tbody></table><br><div class="row"><div class="col-sm-10"></div><div class="col-sm-1"><button type="submit" class="btn btn-primary">Aceptar</button></div></div></div></div></form>');
}]);
})();
