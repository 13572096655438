(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/ayuda.tpl.html',
    '<div class="panel panel-default"><div class="panel-heading"><b>Seleccione el módulo para el cual necesita ayuda</b></div><div class="panel-body"><div class="row"><div class="col-sm-12"><label class="control-label" for="moduloId">Módulo</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="moduloId" ng-model="moduloId" on-select="setModuloAyuda($item)" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.moduloDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.tipoTransporteId as item in dataModuloAyuda | filter: {moduloDesc: $select.search}"><div ng-bind-html="item.moduloDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-12"><h1>Teléfono: <b>{{moduloAyuda.telefono}}</b></h1><h1>Correo: <b>{{moduloAyuda.correo}}</b></h1></div></div></div></div>');
}]);
})();
