(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/clave/manual.show.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h4><b>Lista de guias y formatos</b></h4></div><div class="box-body"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
