(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/home/anuncio.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h3><i class="fa fa-warning text-red"></i><b>{{tituloAnuncio}}</b></h3><p></p><p></p><h4>Información de interés</h4><p></p></div></div><div class="box-body"><h2>{{detalleAnuncio}}</h2></div></div>');
}]);
})();
