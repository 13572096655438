(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/common/tablefooter-tpl.tpl.html',
    '<tr><td colspan="100%" class="text-center"><div st-pagination="" st-items-by-page="smartTable.itemsByPage" st-displayed-pages="smartTable.displayedPages"></div></td></tr>');
}]);
})();
