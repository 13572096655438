(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/sistema.repoblacion.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Sistema de repoblación forestal</h4><p></p><p><small>Ingrese la informaci&oacute;n</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormSistema(form.$valid)" novalidate=""><div class="row"><div class="col-sm-6" ng-class="{ \'has-error\' : form.sistemaRepoblacionId.$invalid && form.$submitted }"><label class="control-label" for="sistemaRepoblacionId">Sistema de repoblación</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="sistemaRepoblacionId" ng-model="sistemaRepoblacion.sistemaRepoblacionId" on-select="setSistemaRepoblacion($item)" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.sistemaRepoblacionDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.sistemaRepoblacionId as item in dataSistemaRepoblacion | filter: {sistemaRepoblacionDesc: $select.search}"><div ng-bind-html="item.sistemaRepoblacionDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="col-sm-6" ng-class="{ \'has-error\' : form.cantidad.$invalid && form.$submitted }"><div class="fg-line form-group"><label class="control-label" for="cantidad">{{label}}</label> <input type="text" class="form-control input-sm" name="cantidad" ng-model="sistemaRepoblacion.cantidad" placeholder="Cantidad" required="" numbers-only=""></div></div></div><div class="row"><div class="col-sm-12"><button type="submit" class="btn btn-primary">Enviar</button> <button type="button" class="btn btn-default" ng-click="cerrarVentana()">Cerrar</button></div></div></form></div></div>');
}]);
})();
