(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/personafinca/personafinca.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-8"><div class="box-title pull-left"><p></p><h2><i class="fa fa-home fa-sm"></i> {{ module }}</h2><p></p><p><small>Listado de {{ module }}</small></p></div></div><div class="col-sm-2"><br><br><button class="btn btn-success pull-right" ng-click="agregarRegistroFinca()"><i class="fa fa-plus fa-lg">Agregar</i></button></div><div class="col-sm-2"><br><br><button class="btn btn-warning" type="button" ng-click="buscarFinca()"><i class="zmdi zmdi-search"></i>Buscar</button></div></div></div><div class="box-body"><div ui-grid="gridOptionsFinca" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
