(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/requisito.dictamen.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Revise el expediente y verifique que cumple con (marque las casillas para continuar)</h4><p></p></div></div><div class="box-body"><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col" class="text-center" width="5%">No.</th><th scope="col" class="text-center" width="85%">Descripción</th><th scope="col" class="text-center" width="10%"><label class="col-sm-2 checkbox-inline"><input id="checkAll" type="checkbox" ng-model="todo" ng-change="seleccionarTodo()"> Seleccionar</label></th></tr></thead><tbody><tr ng-repeat="item in dataRequisito track by $index" class="info"><td scope="col">{{$index + 1}}</td><td scope="col">{{item.requisitoDesc}}</td><td align="center"><div class="button-checkbox"><button type="button" class="btn btn-xs" ng-class="item.class" data-color="info" ng-click="marcarRequisito(item)"></button> <input type="checkbox" class="hidden" ng-model="item.marcado" ng-true-value="1" ng-false-value="0"></div></td></tr></tbody></table><div class="row"><div class="col-sm-9"></div><div class="col-sm-1"><button type="button" class="btn btn-primary" ng-click="aceptarRequisito()">Aceptar</button></div><div class="col-sm-1"><button type="button" class="btn btn-danger" ng-click="cerrarVentana()">Cerrar</button></div></div></div></div>');
}]);
})();
