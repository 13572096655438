(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/boleta.exento.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Utilice el botón "Cargar archivo" para leer los datos de la boleta de verificación de campo</h4><p></p><p><small>Para finalizar presione aceptar</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormData(form.$valid)" novalidate=""><label class="btn btn-primary btn-file">Cargar archivo <input type="file" style="display: none;" name="file" accept=".xls, .xlsx, .ods, .csv" boleta-exento="" opts="gridData"></label><div class="row"><div class="col-sm-12"><div ui-grid="gridData" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div><br><div class="row"><div class="col-sm-9"></div><div class="col-sm-1"><button type="submit" class="btn btn-success">Aceptar <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></button></div><div class="col-sm-1"><button type="button" class="btn btn-danger" ng-click="cerrarVentana()">Cancelar <i class="fa fa-close" aria-hidden="true"></i></button></div></div></form></div></div>');
}]);
})();
