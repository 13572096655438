(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/forbidden/forbidden.show.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border" align="center"><h1>Acceso denegado o prohibido</h1></div><div class="box-body"><div class="row"><div class="col-sm-12" align="center"><img src="img/denegado.png"></div></div></div></div>');
}]);
})();
