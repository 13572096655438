(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/gestion/finca.region.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4><b>Elija a la que se ha abocado comunmente con su finca. O puede llamar a cualquiera de ellas para que le indiquen la correcta según la ubicación.</b></h4></div><div class="panel-body"><div class="table-responsive"><table class="table i-table table-bordered"><thead><tr class="success"><th scope="col" class="text-center" width="5%">No.</th><th scope="col" class="text-center">Subregión</th><th scope="col" class="text-center">Dirección</th><th scope="col" class="text-center" width="10%">Opción</th></tr></thead><tbody><tr ng-repeat="item in dataSubregion track by $index" class="info"><td scope="col">{{$index + 1}}</td><td scope="col">{{item.subregionDesc}}</td><td scope="col">{{item.municipioDesc}}</td><td scope="col"><button class="btn btn-success btn-xs" type="button" ng-click="seleccionarSubregion(item)"><i class="fa fa-hand-o-up"></i> Seleccionar</button></td></tr></tbody></table></div></div></div>');
}]);
})();
