(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/clave/clave.tpl.html',
    '<menu-tabset tabs="tabs"></menu-tabset><div class="slide" ui-view=""></div>');
}]);
})();
