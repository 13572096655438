(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.provconsumo.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Indique el número de providencia</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="enviarTarea(form.$valid)" novalidate=""><div class="row"><div class="col-sm-6" ng-class="{ \'has-error\' : form.tecnicoId.$invalid && form.$submitted }"><label class="control-label" for="tecnicoId">Técnico</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="tecnicoId" ng-model="dataTask.tcPersonaTraslado.personaId" theme="bootstrap" on-select="setTecnico($item)" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.personaDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.personaId as item in dataTecnico | filter: {personaDesc: $select.search}"><div ng-bind-html="item.personaDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="col-sm-2"></div><div class="col-sm-4"><button type="submit" class="btn btn-success">Asignar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaAsignacion(false)">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.consumo">Cancelar</a></div></div></form></div></div>');
}]);
})();
