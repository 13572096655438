(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/recuperar.password.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-hand-o-right"></i> Recuperar contraseña</h2><p></p><p><small>Ingrese la informaci&oacute;n</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormRecuperar(form.$valid)" novalidate=""><div class="row"><div class="col-sm-12" ng-class="{ \'has-error\' : form.correo.$invalid && form.$submitted }"><div class="fg-line form-group"><label class="control-label" for="correo">Ingrese el correo asociado a su cuenta</label> <input type="email" class="form-control input-sm" name="correo" ng-model="recuperar.correo" placeholder="Correo" required=""></div></div></div><div class="row"><div class="col-sm-12"><div vc-recaptcha="" theme="\'light\'" key="claveCaptcha" on-create="setWidgetId(widgetId)" on-success="setResponse(respuestaCaptcha)" on-expire="cbExpiration()"></div></div></div><div class="row"><div class="col-sm-12"><button type="submit" class="btn btn-primary">Enviar</button> <button type="button" class="btn btn-default" ng-click="cerrarVentana()">Cerrar</button></div></div></form></div></div>');
}]);
})();
