(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/programar.tarea.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Indique la fecha y hora para la inspección de campo</h4><p></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormRecibir(form.$valid)" novalidate=""><div class="row"><div class="col-sm-6" ng-class="{ \'has-error\' : form.fechaInspeccion.$invalid && form.$submitted }"><label class="control-label" for="fechaInspeccion"><i class="fa fa-calendar"></i> Fecha</label><p class="input-group"><input type="text" name="fechaInspeccion" class="form-control" uib-datepicker-popup="{{dateOptions.format}}" ng-model="ttTarea.ttVentaExento.fechaInspeccion" is-open="openedDates.fechaInspeccion" min-date="minDate" datepicker-options="dateOptions" ng-click="openDate($event, \'fechaInspeccion\')" current-text="Hoy" clear-text="Limpiar" close-text="Cerrar" required=""> <span class="input-group-btn"><button type="button" class="btn btn-default" ng-click="openDate($event, \'fechaInspeccion\')"><i class="glyphicon glyphicon-calendar"></i></button></span></p></div><div class="col-sm-6" ng-class="{ \'has-error\' : form.fechaInspeccion.$invalid && form.$submitted }"><div class="form-group"><label class="control-label"><i class="fa fa-clock-o"></i> Hora (24 hrs.)</label><br><div class="form-group"><input class="form-control" ng-model="ttTarea.ttVentaExento.fechaInspeccion" name="fechaInspeccion" bs-timepicker="" data-time-format="HH:mm" data-length="1" data-minute-step="1" data-arrow-behavior="picker" type="text" required=""></div></div></div></div><div class="row"><div class="col-sm-12 text-right"><button type="submit" class="btn btn-success">Aceptar <i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></button> <button type="button" class="btn btn-danger" ng-click="cerrarVentana()">Cancelar <i class="fa fa-close" aria-hidden="true"></i></button></div></div></form></div></div>');
}]);
})();
