(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.subregional.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Notificación de resolución y generar cedula de notificación</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="notificarResolucion(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.codigo.$invalid && form.$submitted }"><label class="control-label" for="codigo">No. Oficio (Aviso)</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="codigo" ng-model="dataTask.ttSeguimientoTarea.codigo" placeholder="Oficio" ng-required="dataTask.ttPadre.aprobado == 1"></div></div><div class="col-sm-3" ng-show="dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2 && dataTask.ttPadre.aprobado == 1" ng-class="{ \'has-error\' : form.tipoCostoDesc.$invalid && form.$submitted }"><label class="control-label" for="oficio">No. Oficio (Póliza)</label><div class="fg-line form-group"><input type="text" name="oficio" class="form-control input-sm" ng-model="dataTask.ttSeguimientoTarea.fundamento" placeholder="No. Oficio (Póliza)"></div></div><div class="col-sm-3" ng-show="dataTask.tcTask.taskId == 47 && dataTask.ttPadre.aprobado == 1" ng-class="{ \'has-error\' : form.numero.$invalid && form.$submitted }"><label class="control-label" for="numero">Cantidad de notas de envío</label><div class="fg-line form-group"><input type="text" name="numero" class="form-control input-sm" ng-model="dataTask.ttSeguimientoTarea.numero" placeholder="Notas de envío" ng-required="dataTask.tcTask.taskId == 47 && dataTask.ttPadre.aprobado == 1"></div></div><div class="col-sm-9 text-right"><button type="submit" class="btn btn-success">Notificar</button> <button type="button" class="btn btn-warning" ng-click="vistaPrevia()">Ver</button> <a class="btn btn-danger" ui-sref="index.tarea.list">Cancelar</a></div></div></form></div></div>');
}]);
})();
