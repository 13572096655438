(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/fuentesemillera/fuentesemillera.ventaplanta.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-check fa-lg"></i>Listado de ventas de semilla, lote: {{ dataFuenteSemillera.loteSemilla.codigo }}</h4><p></p><p><small>Venta de semilla (Fuente semillera: {{dataFuenteSemillera.data.fuenteSemilleraDesc}})</small></p></div><br><br></div><div class="box-body"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
