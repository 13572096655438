(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.licencia.tpl.html',
    '<style>\n' +
    '.btn.active {\n' +
    '  background-color: #f77;\n' +
    '}\n' +
    '</style><div class="panel panel-primary"><div class="panel-heading"><div class="row"><div class="col-sm-3"><h4><b>Licencia de aprovechamiento</b></h4></div></div></div><div class="panel-body"><div class="row"><div class="col-sm-3"><label class="control-label" for="numeroResolucion">No. de Licencia</label><div class="fg-line form-group"><input type="text" name="numeroResolucion" class="form-control input-sm" ng-model="dataTask.ttSeguimientoTarea.numeroResolucion" placeholder="Número de licencia"></div></div><div class="col-sm-3"><label class="control-label" for="providencia">No. de providencia</label><div class="fg-line form-group"><input type="text" name="providencia" class="form-control input-sm" ng-model="dataTask.ttSeguimientoTarea.providencia" placeholder="Número de providencia"></div></div><div class="col-sm-6 text-right"><br><button type="button" class="btn btn-danger" ng-click="enviarTarea()">Guardar</button> <button type="button" class="btn btn-info" ng-click="vistaPreviaLicencia()">Vista previa</button> <a class="btn btn-success" ui-sref="index.tarea.list">Regresar</a></div></div></div></div>');
}]);
})();
