(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/poligono.list.tpl.html',
    '<div class="box box-success"><div class="box-body"><div class="row"><div class="col-sm-12"><label>Polígonos</label><div class="fg-line form-group"><table align="center"><thead><tr class="centrar"><th scope="col" width="20%">No.</th><th scope="col" width="30%">Área</th><th scope="col">Imprimir</th></tr></thead><tbody><tr ng-repeat="item in dataPoligonos track by $index"><td scope="col">{{item.numero}}</td><td scope="col">{{item.area}}</td><td scope="col"><button type="button" class="btn btn-default" title="Ver mapa" ng-click="visualizarMapa(item)"><i class="fa fa-print text-blue"></i> Imprimir</button></td></tr></tbody></table></div></div></div></div></div>');
}]);
})();
