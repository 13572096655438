(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tiponotificacion/tiponotificacion.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-check fa-lg"></i> {{ module }}</h2><p></p><p><small>Listado de {{ module }}</small></p></div><br><br><button class="btn btn-success pull-right" ui-sref="^.add"><i class="fa fa-plus fa-lg">Agregar</i></button></div><div class="box-body"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
