(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/notaenvio/notaenvio.refrescar.tpl.html',
    '<div class="box box-success"><div class="box-header"><h4>Actualizar libro electrónico</h4></div><div class="box-body"><form rol="form" name="form" ng-submit="submitFormActualizar(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.saldoAnterior.$invalid && form.$submitted }"><label class="control-label" for="saldoAnterior">Saldo al iniciar el mes</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="saldoAnterior" ng-model="corte.saldoAnterior" placeholder="Saldo al iniciar" numbers-only="" required=""></div></div><div class="col-sm-3" ng-class="{ \'has-error\' : form.correlativoAnterior.$invalid && form.$submitted }"><label class="control-label" for="correlativoAnterior">Correlativos al iniciar el mes</label><div class="fg-line form-group"><textarea name="form-control input-sm" rows="4" cols="80" ng-model="corte.correlativoAnterior" placeholder="Correlativos" required=""></textarea></div></div></div><div class="row"><div class="col-sm-10"></div><div class="col-sm-1"><button type="submit" class="btn btn-primary">Aceptar</button></div></div></form></div></div>');
}]);
})();
