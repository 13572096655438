(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.enmiendainforme.tpl.html',
    '<div class="panel panel-primary"><div class="panel-heading"><div class="row"><div class="col-sm-6"><h4>{{titulo}}</h4></div><div class="col-sm-2"><label class="control-label" for="codigo">No. {{codigo}}</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="codigo" ng-model="dataTask.codigo" placeholder="No. Oficio" required=""></div></div><div class="col-sm-3" ng-show="!esProvidencia"><br><button type="button" class="btn btn-info" ng-click="providenciarTecnico()" title="Regresar tarea a técnico">Providenciar</button></div></div></div><div class="panel-body"><div class="row" ng-show="!esProvidencia"><div class="col-sm-12"><div class="panel panel-info"><div class="panel-heading"><button type="button" class="btn btn-success btn-xs pull-right" ng-click="agregarFundamento()"><i class="fa fa-plus" aria-hidden="true"></i></button> <b>Si desea agregar otras enmiendas, presione sobre el botón con el signo más (+)</b></div><div class="panel-body"><table class="table i-table"><thead><tr class="success"><th width="5%" class="p-b-10" align="center">No.</th><th width="90%" class="p-b-10" align="center">Descripción</th><th width="5%" class="p-b-10" align="center"></th></tr></thead><tbody><tr ng-repeat="fundamento in dataTask.observaciones track by $index" class="info"><td width="5%" class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="$index + 1"></h5></td><td width="90%" class="p-b-10"><textarea rows="2" class="form-control input-sm" name="descripcion" ng-model="fundamento.descripcion" placeholder="Descripción de la enmienda">\n' +
    '                  </textarea></td><td width="5%" align="center"><button type="button" class="btn btn-danger btn-xs" title="Quitar fundamento" ng-click="borrarDetalle(fundamento)"><i class="fa fa-minus" aria-hidden="true"></i></button></td></tr></tbody></table></div></div></div></div><div class="row" ng-show="esProvidencia"><div class="col-sm-6" ng-class="{ \'has-error\' : form.tecnicoId.$invalid && form.$submitted }"><label class="control-label" for="estadoId">Técnico</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="tecnicoId" ng-model="dataTask.tcPersonaTraslado.personaId" theme="bootstrap" on-select="setTecnico($item)" sortable="true"><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.personaDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.personaId as item in dataTecnico | filter: {personaDesc: $select.search}"><div ng-bind-html="item.personaDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-8"></div><div class="col-sm-4 pull-right"><button type="button" class="btn btn-success" ng-click="enviarTarea()">Guardar</button> <button type="button" class="btn btn-primary" ng-click="vistaPreviaNotificacion()">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.exentolist">Cancelar</a></div></div></div></div>');
}]);
})();
