(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/seguimiento/involucrado.gestion.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h4><i class="fa fa-hand-o-right"></i> Coincidencias encontradas</h4><p></p></div></div><div class="box-body"><table class="table i-table"><thead><tr class="success"><th width="10%" class="p-b-10" align="center">No.</th><th width="60%" class="p-b-10" align="center">Nombre</th><th width="30%" class="p-b-10" align="center">Expediente</th></tr></thead><tbody><tr ng-repeat="item in dataHistorial track by $index" class="info"><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="$index + 1"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.tcPersona.personaDesc"></h5></td><td class="p-b-10"><h5 class="f-400 m-b-5" ng-bind="item.expediente"></h5></td></tr></tbody></table></div></div>');
}]);
})();
