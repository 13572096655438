(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/tarea.resventa.tpl.html',
    '<div class="panel panel-info"><div class="panel-heading"><h4>Resolución sobre solicitud de venta de notas</h4></div><div class="panel-body"><form rol="form" name="form" ng-submit="notificarResolucion(form.$valid)" novalidate=""><table class="table table-bordered" border="1"><thead><tr><td class="bg-success"><h4><b>Observaciones</b></h4></td><td class="bg-success"><button type="button" name="button" class="btn btn-success btn-xs" ng-click="agregarRegistro()" title="Agregar observación"><i class="fa fa-plus" aria-hidden="true"></i></button></td></tr></thead><tbody><tr ng-repeat="detalle in dataTask.observaciones.observaciones track by $index"><td class="bg-info"><textarea name="descripcion" id="descripcion" cols="30" rows="2" class="form-control input-sm" ng-model="detalle.descripcion" placeholder="Observacion" required=""></textarea></td><td width="5%" class="bg-info"><button type="button" class="btn btn-danger btn-xs" ng-click="quitarDetalle(detalle)" title="Quitar observación"><i class="fa fa-minus"></i></button></td></tr></tbody></table><br><div class="row"><div class="col-sm-7"></div><div class="col-sm-4"><button type="submit" class="btn btn-success">Notificar</button> <button type="button" class="btn btn-warning" ng-click="vistaPreviaNotificacion()">Vista previa</button> <a class="btn btn-danger" ui-sref="index.tarea.exentolist">Cancelar</a></div></div></form></div></div>');
}]);
})();
