(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/exento/exento.list.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-6"><p></p><h2><i class="fa fa-check fa-lg"></i> {{ module }}</h2><p></p><p><small>Lista de {{ module }}</small></p></div><div class="col-sm-4"><br><div class="form-group"><h5>Al registrarte aceptas nuestros <a ur-sref="#" ng-click="imprimirAdhesion()">términos y condiciones</a></h5></div></div><div class="col-sm-2"><br><br><button class="btn btn-success pull-right" ui-sref="^.agregar"><i class="fa fa-plus fa-lg">Agregar</i></button></div></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="row"><div class="col-sm-3" ng-class="{ \'has-error\' : form.estadoId.$invalid && form.$submitted }"><label class="control-label" for="estadoId">Filtrar por estado</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="estadoId" ng-model="data.estadoId" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.estadoDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.estadoId as item in dataEstado | filter: {estadoDesc: $select.search}"><div ng-bind-html="item.estadoDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="col-sm-1"><br><button type="submit" class="btn btn-primary">Filtrar</button></div></div><br><div class="row"><div class="col-sm-12"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div></form></div></div>');
}]);
})();
