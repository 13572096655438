(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tarea/subregional.suspender.tpl.html',
    '<style>\n' +
    '.btn.active {\n' +
    '  background-color: #f77;\n' +
    '}\n' +
    '</style><div class="panel panel-primary"><div class="panel-heading"><div class="row"><div class="col-sm-6"><h3>Suspensión de gestión</h3></div><div class="col-sm-3"><label class="control-label" for="numero">No. de dictamen</label><div class="fg-line form-group"><input type="text" name="numero" class="form-control input-sm" ng-model="dataTask.ttSeguimientoTarea.numeroResolucion" placeholder="Número de dictamen"></div></div></div></div><div class="panel-body"><form rol="form" name="form" ng-submit="enviarTarea(form.$valid)" novalidate=""><div class="row"><div class="col-sm-4"><label class="control-label">¿Finalizar proceso?</label><switch id="aprobar" name="aprobar" ng-model="data.approved" on="Si" off="No" class="green" ng-change="setAprobarSuspension()"></switch></div></div><div class="row"><div class="col-sm-12"><label class="control-label" for="observaciones">Observaciones</label><div class="fg-line form-group"><textarea name="observaciones" rows="4" cols="80" ng-model="dataTask.ttSeguimientoTarea.observaciones" class="form-control input-sm"></textarea></div></div></div><div class="row"><div class="col-sm-12 text-right"><button type="submit" class="btn btn-danger">Guardar</button> <button type="button" class="btn btn-info" ng-click="vistaPrevia()">Vista previa</button> <a class="btn btn-success" ui-sref="index.tarea.list">Regresar</a></div></div></form></div></div>');
}]);
})();
