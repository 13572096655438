(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/usuario/usuario.cambio.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="row"><div class="col-sm-6"><div class="box-title pull-left"><p></p><h3><i class="fa fa-check fa-lg"></i> Solicitudes de cambio de perfil</h3><p></p><p><small>Revise la información y resuelva la solicitud</small></p></div></div><div class="col-sm-1"><br><button class="btn btn-success" ng-click="actualizar()" title="Clic para actualizar lista de solicitudes"><i class="fa fa-refresh"></i></button></div><div class="col-sm-2"><label class="control-label" for="departamentoId">Departamento</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="departamentoId" ng-model="cambio.departamentoId" theme="bootstrap" on-select="setDepartamento($item)" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.departamentoDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.departamentoId as item in dataDepartamento | filter: {departamentoDesc: $select.search}"><div ng-bind-html="item.departamentoDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div><div class="col-sm-3"><label class="control-label" for="municipioId">Municipio</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="municipioId" ng-model="cambio.municipioId" theme="bootstrap" on-select="setMunicipio($item)" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.municipioDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.municipioId as item in dataMunicipio | filter: {municipioDesc: $select.search}"><div ng-bind-html="item.municipioDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div></div><div class="box-body"><div ui-grid="gridOptions" ui-grid-pagination="" ui-grid-auto-resize="" class="myGrid"></div></div></div>');
}]);
})();
