(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/tipoexento/tipoexento.add.tpl.html',
    '<div class="box box-success"><div class="box-header"><div class="box-title pull-left"><p></p><h2><i class="fa fa-hand-o-right fa-lg"></i> {{ !$state.params.tipoExentoId ? \'Nuevo\' : \'Editar\' }} {{ module }}</h2><p></p><p><small>Ingrese la informaci&oacute;n</small></p></div></div><div class="box-body"><form rol="form" name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="row"><div class="col-sm-4" ng-class="{ \'has-error\' : form.tipoExentoDesc.$invalid && form.$submitted }"><label class="control-label" for="tipoExentoDesc">Nombre</label><div class="fg-line form-group"><input type="text" class="form-control input-sm" name="tipoExentoDesc" ng-model="data.tipoExentoDesc" placeholder="Nombre" required=""></div></div><div class="col-sm-4" ng-class="{ \'has-error\' : form.estadoId.$invalid && form.$submitted }"><label class="control-label" for="estadoId">Estado</label><div class="fg-line form-group"><ui-select class="form-control input-sm" name="estadoId" ng-model="data.estadoId" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione">{{$select.selected.estadoDesc}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.estadoId as item in dataEstado | filter: {estadoDesc: $select.search}"><div ng-bind-html="item.estadoDesc | highlight: $select.search"></div></ui-select-choices></ui-select></div></div></div><div class="row"><div class="col-sm-12"><button type="submit" class="btn btn-primary">Guardar</button> <a class="btn btn-default" ui-sref="^.list">Cancelar</a></div></div></form></div></div>');
}]);
})();
